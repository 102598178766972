import React, { useCallback } from 'react';
import { Button, message, Modal } from 'antd';
import { Trash2Icon } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { csv } from '@/api';
import { displayOrCatchMessage } from '@/helpers';

const CsvDeleteModal = ({
  csvId, setVisible, visible, setReload, setLoading, ...props
}) => {
  const onCancel = useCallback(() => {
    setVisible(false);
    setLoading(false);
  }, []);

  const deleteCsv = useMutation({
    mutationKey: ['deleteCsv', csvId],
    mutationFn: () => csv.deleteCsv(csvId),
    onSuccess: () => {
      message.success('The file has been deleted successfully', 3);
      setReload(true);
      setLoading(false);
      setVisible(false);
    },
    onError: (e) => {
      message.error('Failed to delete');
      displayOrCatchMessage(e);
      setLoading(false);
      setVisible(false);
    },
  });

  const onOk = () => {
    deleteCsv.mutate(csvId);
  };

  return (
    <Modal
      title={(
        <>
          <Trash2Icon size={16} />
          {' '}
          Delete CSV
        </>
            )}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button className="float-left" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          className="delete-button"
          key="submit"
          type="danger"
          loading={deleteCsv.isLoading}
          onClick={onOk}
        >
          Yes, delete
        </Button>,
      ]}
      visible={visible}
      className="taxonomy-modal"
      {...props}
    >
      <div className="category-modal-body">
        <p className="mb-0">Are you sure you want to delete this csv file and all data imported with it?</p>
      </div>
    </Modal>
  );
};

export default CsvDeleteModal;
