import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const processing = (filters, page, sort) => axios.get(buildQuery('/api/csv/processing', filters, {
  ...(page ? { page } : undefined),
  ...(sort ? { sort } : undefined),
  team_id: auth.team().id,
})).then((response) => response).catch((e) => Promise.reject(e));

const uploaded = () => axios.post('/api/csv/uploaded', {
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const getFiles = () => axios.get('/api/csv/flatfile/files');

const updateCsvFilename = (id, values) => axios.put(`/api/csv/flatfile/file/${id}`, values, {
  params: {
    team_id: auth.team().id,
  },
});

const deleteCsv = (id) => axios.delete(`/api/csv/${id}`, {
  params: {
    team_id: auth.team().id,
  },
});

const batch = (id, rows, mappings, batchNumber, fileName, type) => axios.post('/api/csv/batch', {
  id,
  rows,
  mappings,
  filename: fileName,
  batch_num: batchNumber,
  data_type: type,
  team_id: auth.team().id,
});

const csv = {
  processing,
  uploaded,
  getFiles,
  updateCsvFilename,
  deleteCsv,
  batch,
};

export default csv;
