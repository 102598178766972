/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { displayOrCatchMessage } from '@/helpers';
import { cn } from '@/helpers/util';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { csv } from '@/api';
import CsvDeleteModal from '@/base-components/integrations/components/csv/CsvDeleteModal';
import useIntegrationsStore from '../store/useIntegrationsStore';

const EditFileRow = ({
  id, filename, onCancel, onSave,
}) => {
  const [value, setValue] = useState(filename);
  return (
    (
      <div className="flex justify-between min-w-full space-x-2">
        <div className="flex-1">
          <Input
            onChange={debounce((e) => {
              setValue(e.target.value);
            }, 150)}
            defaultValue={value}
            placeholder="File name"
          />
        </div>
        <div className="space-x-2">
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onSave(id, { name: value })} type="primary">Save</Button>
        </div>
      </div>
    )
  );
};

const FileRow = ({ file, setReload }) => {
  const [edit, setEdit] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const editLabel = () => {
    setEdit(true);
  };

  const handleSave = async (id, payload) => {
    try {
      await csv.updateCsvFilename(id, payload);
      setReload((prev) => !prev);
      message.success('The file name has been updated successfully', 1.5);
      setEdit(false);
    } catch (e) {
      displayOrCatchMessage(e);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    setVisibleDeleteModal(true);
  };

  return (
    <>
      <CsvDeleteModal
        csvId={file.id}
        setVisible={setVisibleDeleteModal}
        visible={visibleDeleteModal}
        setReload={setReload}
        setLoading={setLoading}
      />
      <li className={cn('text-[#595959] border-0 border-b border-[#E8E8E8] border-solid last:border-none flex justify-between font-[Gordita-Medium]', !edit ? 'px-3.5 py-2.5' : 'p-2.5 bg-[#F6F9FB]')}>
        {edit ? (
          <EditFileRow {...file} onCancel={() => setEdit(false)} onSave={handleSave} />
        ) : (
          <>
            <div className="flex items-center space-x-3">
              <span>{file.filename}</span>
            </div>
            <div>
              <Button onClick={editLabel} type="text">
                <EditOutlined />
              </Button>
              <Button disabled={loading} onClick={handleDelete} type="text">
                <DeleteOutlined />
              </Button>
            </div>
          </>
        )}
      </li>
    </>
  );
};

function FilesUploaded({ files }) {
  const { actions } = useIntegrationsStore();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    actions.updateCsvFiles();
  }, [reload]);

  return (
    <ul className="mt-3 list-none p-0 border rounded-md border-solid border-[#E8E8E8] max-w-md">
      {files.map((file) => (
        <FileRow key={file.id} file={file} setReload={setReload} />
      ))}
    </ul>
  );
}

export default function CSVConntected() {
  const { state } = useIntegrationsStore();
  return (
    <>
      <p className="text-[var(--grey)]">
        Your uploaded spreadsheets
      </p>
      <FilesUploaded files={state.csvFiles} />
    </>
  );
}
